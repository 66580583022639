import { ZipCodeFormBlock } from "@core/coupledStoryblokBlocks";
import { EnrollmentBanner } from "@core/enrollment/EnrollmentBanner";
import { Layout } from "@core/layout";
import type { GetMarketingPageQuery } from "@octopus-energy/storyblok-cms";
import {
  CustomBlocks,
  DynamicBlockProvider,
  MarketingPage,
  getMarketingPageDataServer,
} from "@octopus-energy/storyblok-cms";
import * as Sentry from "@sentry/nextjs";
import type { GetStaticProps, NextPage } from "next";
import { useRouter } from "next/router";

const HOMEPAGE_SLUG = "homepage-preview";

type HomePageProps = {
  ssrPageData?: GetMarketingPageQuery["MarketingpageItem"];
  ssrSpanishPageData?: GetMarketingPageQuery["MarketingpageItem"];
};

const HomePage: NextPage<HomePageProps> = ({
  ssrPageData,
  ssrSpanishPageData,
}) => {
  const customBlocks: CustomBlocks = {
    ZipCodeFormBlock,
  };
  const locale = useRouter().locale;

  return (
    <DynamicBlockProvider customBlocks={customBlocks}>
      <Layout>
        <EnrollmentBanner />
        {locale === "en" && <MarketingPage ssrPageData={ssrPageData} />}
        {locale === "es" && <MarketingPage ssrPageData={ssrSpanishPageData} />}
      </Layout>
    </DynamicBlockProvider>
  );
};

export default HomePage;

const getHomepageData = async (preview: boolean | null) => {
  try {
    // Fetch
    const { MarketingpageItem: ssrPageData } = await getMarketingPageDataServer(
      HOMEPAGE_SLUG,
      preview
    );

    const { MarketingpageItem: ssrSpanishPageData } =
      await getMarketingPageDataServer(`es/${HOMEPAGE_SLUG}`, preview);

    // Check if page was found.
    if (ssrPageData || ssrSpanishPageData) {
      return { ssrPageData, ssrSpanishPageData };
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStaticProps: GetStaticProps<HomePageProps> = async ({
  locale = "en",
  preview = null,
}) => {
  try {
    const data = await getHomepageData(preview);

    if (data) {
      return {
        props: {
          ssrPageData: data.ssrPageData,
          ssrSpanishPageData: data.ssrSpanishPageData,
          preview,
          locale,
        },
        revalidate: 60 * 60,
      };
    }

    return {
      props: {
        ssrPageData: undefined,
        ssrSpanishPageData: undefined,
      },
      revalidate: 60 * 60,
    };
  } catch (error) {
    Sentry.withScope((scope) => {
      Sentry.setTag("requestType", "Home SSR Build");
      scope.setExtra("extraInfo", { data: error });
      Sentry.captureException("Failed to SSR build Home Page.");
    });

    return {
      props: {
        ssrFeaturedPosts: undefined,
      },
      revalidate: 60 * 60,
    };
  }
};
