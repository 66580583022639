import { ZipCodeForm } from "@core/molecules";
import { Box, Stack, Typography } from "@octopus-energy/coral-mui";
import type { StoryblokBlock } from "@octopus-energy/storyblok-cms";
import useTranslation from "next-translate/useTranslation";
import type { FC } from "react";

export type ZipCodeFormBlockProps = StoryblokBlock & {
  headingText?: string | null;
  cta?: string | null;
  alignment?: "left" | "right" | "center" | "justify" | null;
  affiliate?: "string" | null;
};

export const ZipCodeFormBlock: FC<ZipCodeFormBlockProps> = ({
  headingText,
  cta,
  alignment,
  affiliate,
}) => {
  const flexAlignment = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
    justify: "center",
  }[alignment || "left"];

  const { t } = useTranslation("marketing/homepage");

  const affiliateString = affiliate
    ? affiliate
        .toLowerCase()
        .trim() // remove leading and ending whitespace
        .replace(/[^\w\s-]/g, "") // remove all characters that are not letters, numbers, spaces, or hyphens
        .replace(/[\s_-]+/g, "-") // replace one or more consecutive spaces, underscores, or hyphens with a single hyphen
        .replace(/^-+|-+$/g, "") // ensure that the modified string doesn't start or end with a hyphen
    : null;

  return (
    <Stack
      width="100%"
      component="section"
      mt={4.5}
      mb={4.5}
      alignItems={flexAlignment}
      justifyContent="center"
    >
      {headingText && (
        <Typography variant="h5" align={alignment || "left"} mb={0.5}>
          {headingText}
        </Typography>
      )}
      <Box maxWidth="435px">
        <ZipCodeForm
          noHeading
          cta={cta || t("zipCodeButton")}
          affiliate={affiliateString}
        />
      </Box>
    </Stack>
  );
};
